import DropDownMenu_SeparateBasicMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/SeparateBasicMenuButtonSkin/SeparateBasicMenuButtonSkin.skin';


const DropDownMenu_SeparateBasicMenuButtonSkin = {
  component: DropDownMenu_SeparateBasicMenuButtonSkinComponent
};


export const components = {
  ['DropDownMenu_SeparateBasicMenuButtonSkin']: DropDownMenu_SeparateBasicMenuButtonSkin
};


// temporary export
export const version = "1.0.0"
